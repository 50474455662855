import React, { useState } from "react";
import axios from "axios";
import { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AdminSearch = () => {

    const [total, setTotal] = useState([])

    let immediate = useRef([])


  const [referrals, setReferrals] = useState([]);

  const [searchNumber, setSearchNumber] = useState("");

  const createTotal = () => {
    for(let r of immediate){
        for(let c of r.Customers){
            appendTotal();
        }
    }
};

const appendTotal = () => {
    setTotal( arr => [...arr, `${arr.length}`]);
    
};


  const handleChangeSearchNumber = (evt) => {
    setSearchNumber(evt.target.value);
  };

  async function getReferrals() {
    
    try {
      await axios.get(process.env.REACT_APP_DB_SEARCH + searchNumber).then((data) => {
        setReferrals(data.data);
        immediate = (data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }


  const handleSubmit = (evt) => {
    evt.preventDefault();
    immediate = null;
    setTotal([]);
    setReferrals([])
    getReferrals().then(() => {createTotal()})
    

  }

  const {loginWithRedirect,logout, user, isLoading} = useAuth0();
  return (
    
    <div>
      {!user ? (
        <div className="w-screen h-screen grid place-items-center">
            <h1 className="font-bold text-3xl">Login For Admin Priveleges!</h1>
        </div>

      ) : (

        <div className="grid place-items-center">
          <div className="mt-24 md:w-96 w-full">
            <div className="w-full">
            <form onSubmit={handleSubmit} className="w-full " >
              <div className="md:grid md:items-center md:mb-6 mb-12">
                <div className="w-full">
                  <input
                    value={searchNumber}
                    name="userName"
                    onChange={handleChangeSearchNumber}
                    className="bg-gray-200 appearance-none border-2 border-gray-200  w-full py-2 px-4 h-10 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    placeholder="referrer phone number"
                  />
                </div>
                <div className="w-full">
                  <button className="block text-gray-100  font-bold  mb-1 md:mb-0  w-full h-10 mt-6 md:mt-4  hover:bg-gray-400 hover:text-white rounded">
                    Search
                  </button>
                </div>
              </div>
            </form>
            </div>
            <h1 className="font-bold text-3xl text-center">Referrals</h1>
            
            {referrals.map((r) => (
              <div
                key={r.Referrer.phoneNumber}
                className="grid border-2 border-slate-400 rounded shadow-xl my-6 w-full text-xl"
              >
                <p className="font-bold my-2">Referrer:</p>
                <p className="border-b-2 border-slate-400/50 w-full mt-2">
                  {r.Referrer.firstName} {r.Referrer.lastName}:{" "}
                  {r.Referrer.phoneNumber}
                </p>
                <p className="font-bold my-2 ">Customers:</p>
                {r.Customers.map((c) => (
                  <div
                    key={c.phoneNumber + c.firstName + c.lastName}
                    className="flex"
                  >
                    <p className="border-b-2 border-slate-400/50 w-full mt-2">
                      {c.firstName} {c.lastName}: {c.phoneNumber}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
      <div>
        {total.length > 0 ? <h1 className="font-bold text-xl text-center mb-2">Total Customers Referrered: {total.length}</h1> : null}
      </div>
    </div>
  );
};

export default AdminSearch;
