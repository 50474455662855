import React from 'react'

const Footer = () => {
  return (
    <div name="contact" className='w-full bg-stone-900 text-gray-300  px-2 '>
        <div className='max-w-[1240px] py-12 mx-auto'>
            <div className='grid'>
                <h1 className=' text-4xl font-bold underline mx-auto mb-4 md:mt-0'>Contact Us</h1>
                <div className='grid md:grid-cols-3 bg-stone-800/10 rounded-2xl'>
                <h2 className='py-3 mx-auto'>CareTeam</h2>
                <h2 className='py-3 mx-auto'>care@careteamins.com</h2>
                <h2 className='py-3 mx-auto'>800-406-8857</h2>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer