import React from 'react'

const Benefits = () => {
  return (
    <div name="benefits" className="w-full my-32">
    <div className="max-w-[1240px] mx-auto">
        <div className="text-center">
            <h2 className="md:text-5xl text-3xl px-3 md:px-0 font-bold">Health is wealth</h2>
            <p className="md:text-3xl text-xl py-6 px-3 md:px-0 text-gray-500 ">Refer 3 people get $25</p>
        </div>

        <div className="grid md:grid-cols-3 gap-1 px-2 text-center mt-12">
            <div className="border py-8 rounded-xl shadow-xl">
                <p className="text-6xl font-bold text-[#008080]">Refer 3</p>
                <p className="text-gray-400 mt-2">$25 for three people we talked to</p>
            </div>
            <div className="border py-8 rounded-xl shadow-xl">
                <p className="text-6xl font-bold text-[#008080]">Refer 5</p>
                <p className="text-gray-400 mt-2">$50 for three people we talk to and signed up</p>
            </div>
            <div className="border py-8 rounded-xl shadow-xl">
                <p className="text-6xl font-bold text-[#008080]">Refer 10</p>
                <p className="text-gray-400 mt-2">$100 for three people and 10 signups</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default Benefits