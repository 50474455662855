import React from 'react'
import Navbar from './Navbar'
import { Link, animateScroll as scroll} from 'react-scroll'

const Title = () => {
  return (
    <div name="title" className='bg-home bg-cover  h-screen'>
        <div>
            <Navbar></Navbar>
        </div>
        <div className='h-4/5 grid grid-cols-6 2xl:pt-96 md:pt-40 pt-20   mx-auto'>
            <div className='rounded-xl col-start-2 col-span-2'>
                <div className='h-full'>
                    <div className='grid'>
                    <p  className='text-3xl mt-12 italic'></p>
                    <p className='md:text-5xl text-3xl mt-4'>Health Is Wealth</p>
                    <p className='md:text-4xl text-xl mt-6 italic'>Refer those you think would benefit from government subsidized health insurance</p>
                    <Link className='mx-auto md:h-10 w-full' to="referral" smooth={true} duration={500}>
                        <button className='md:mt-16 mt-8 md:h-10 md:w-3/6 w-full mx-auto'>Start Referral</button>
                    </Link>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Title