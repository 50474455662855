import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MinusOutline } from "@styled-icons/evaicons-outline/MinusOutline";
import { produce } from "immer"
import { generate } from 'shortid'
import axios from 'axios';
import emailjs from "emailjs-com"





const Form = () => {

  let history = useHistory();

  const errors = useRef(false);

  const [customers, setCustomers] = useState([
    {
    id: 1,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    }
  ]);

  const newCustomer = () => {
    setCustomers(currentcustomers => [...currentcustomers, 
      {
      id: generate(),
      firstName: '',
      lastName: '',
      phoneNumber: '',
      }

    ])
  }

    const [referrer, setReferrer] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
    })



  const handleChange = (evt) => {
    const nextReferrer = { ...referrer};
    nextReferrer[evt.target.name] = evt.target.value;
    setReferrer(nextReferrer);
  };



  const handleSubmit = (evt) => {
    evt.preventDefault();
    totalValidation();
    
    if(errors.current === true){
      console.log("Errors", errors.current)
    } else {
      postReferral();
      sendEmail();
      
      
      history.push("/success")
    }

    errors.current = false
  }



  async function postReferral() {

    try {
      await axios.post(process.env.REACT_APP_DB_POST, {

        referrer, customers  
    }) 
    } catch (error){
      console.log(error)
    }
    
  }

  function sendEmail() {

    var templateParams = {
      message: "Please see below incoming referral details: \n\n\n" + "Referrer: " + "\n" + referrer.firstName + " " + referrer.lastName + " " + referrer.phoneNumber +"\n\n" + "Customers: " +
      `${customers.map((c) => (   
           "\n" + c.firstName + " " + c.lastName + " " + c.phoneNumber     
   ))}`   
  };


    emailjs.send('service_fzg4244', 'referral_template', templateParams, process.env.REACT_APP_EMAIL_KEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
  

  // async function postReferralSend() {
  //   try {
  //     await axios.post(process.env.REACT_APP_BACKEND_URL_SEND, {

  //       referrer, customers 
  //   }) 
  //   } catch (error){
  //     console.log(error)
  //   }
    
  // }

  



  const totalValidation = () => {

    let errorMessages = []
    
    if(referrer.firstName === "" || referrer.firstName === null) {
        
        let newError = "refferer first name required"
        errorMessages.push(newError)
      }
  
    if(referrer.lastName === "" || referrer.lastName === null) {
      let newError = "refferer Last name required"
      errorMessages.push(newError)
    }
  
    let formattedNumber = "";

    for(let n of referrer.phoneNumber){
      if(n !== "-"){
        formattedNumber = formattedNumber + n
      }
    } 

    if(formattedNumber.length !== 10 || !formattedNumber.match(/^[0-9]+$/)) {
      let newError = "refferer phone number is formatted incorrectly"
      errorMessages.push(newError)
    }


    for(let c of customers){
      if(c.firstName === ""){
        let newError = "customer first name required"
        errorMessages.push(newError)
      }
    }
  
    for(let c of customers){
      if(c.lastName === ""){
        let newError = "customer last name required"
        errorMessages.push(newError)
      }
    }

    for(let c of customers){

      let formattedNumber = "";

      for(let n of c.phoneNumber){
        if(n !== "-"){
          formattedNumber = formattedNumber + n
        }
      } 

      if(!formattedNumber.match(/^[0-9]+$/) || formattedNumber.length !== 10){
        let newError = "customer phone number is formatted incorrectly"
        errorMessages.push(newError)
      }
    }
  
    if(errorMessages.length >= 1){
      console.log(errorMessages)
      errors.current = true;
      alert(JSON.stringify(errorMessages, null, 2))
    }


  
  }


  return (
    <div className="w-full">
      <div className="grid">
        <h1 name="referral" className="mx-auto text-4xl font-bold">
          Start Your Referral
        </h1>
        <MinusOutline className="text-[#008080] w-24 mx-auto " />
        <p className="text-center mb-2 text-lg">*Providing your information gives us consent to contact you*</p>
      </div>
      
      <div className="max-w-[1240px] mx-auto mb-12 h-auto">
        <form className="" onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 gap-4 ">
            <div className="shadow-md rounded-xl lg:px-8 px-2 pt-6 pb-8 mb-4 bg-white">
              <p className="text-4xl pb-6">Referrer</p>
              <label className="text-sm font-bold mb-2" htmlFor="firstName">
                First Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="firstName"
                value={referrer.firstName}
                onChange={handleChange}
                type="text"
                placeholder="First Name"
              ></input>
              <label className="text-sm font-bold mb-2">Last Name</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="lastName"
                value={referrer.lastName}
                onChange={handleChange}
                type="text"
                placeholder="Last Name"
              ></input>
              <label className="text-sm font-bold mb-2">Phone Number</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 mb-6 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="phoneNumber"
                value={referrer.phoneNumber}
                onChange={handleChange}
                type="text"
                placeholder="123-456-7890"
                maxLength={12}
              ></input>
            </div>
            
            {customers.map((p, index) => (
              <div key={p.id}>

              <div className="shadow-md rounded-xl md:px-8 px-2 pt-6 pb-8 mb-4 bg-white">
                <div className="flex">
                  <p className="text-4xl pb-6 w-4/5">Customer {index + 1} {(customers.length <= 3)? <>of 3</>: (customers.length > 3 && customers.length<= 5)? <>of 5</>: (customers.length > 5 && customers.length<= 10)? <>of 10</>: null}</p>
                  { customers.length !== 1 ?
                  <button 
                  className="w-1/5 h-2/5 bg-black relative"
                  onClick={() => {
                    setCustomers(currentcustomers => currentcustomers.filter(x => x.id !== p.id))
                  }}>X</button>
                : <></> } 
                </div>
                <label className="text-sm font-bold mb-2" htmlFor="firstName">
                  First Name
                </label>

                <input onChange={(e) => {
                  const firstName = e.target.value
                  setCustomers((currentcustomers) => 
                    produce(currentcustomers, (v) => {
                      v[index].firstName = firstName;
                    })
                  );
                }}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={p.firstName} placeholder="First Name"  />


                <label className="text-sm font-bold mb-2">Last Name</label>
                <input onChange={(e) => {
                  const lastName = e.target.value
                  setCustomers((currentcustomers) => 
                    produce(currentcustomers, (v) => {
                      v[index].lastName = lastName;
                    })
                  );
                }} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={p.lastName} placeholder="Last Name" type="text" />



                <label className="text-sm font-bold mb-2">Phone Number</label>
                <input onChange={(e) => {
                  const phoneNumber = e.target.value
                  setCustomers((currentcustomers) => 
                    produce(currentcustomers, (v) => {
                      v[index].phoneNumber = phoneNumber;
                    })
                  );
                }} 
                className="shadow appearance-none border rounded w-full py-2 mb-6 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={p.phoneNumber} placeholder="123-456-7890" type="text" maxLength={12} />
                
              </div>
              </div>
              
            ))}
            
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <button
              className="mx-auto md:w-60 w-full h-12  mt-6
                         text-white border bg-[#008080] border-slate-200 
                         hover:bg-indigo-600/20
                         hover:text-indigo-600 rounded-md"
              type="button"
              onClick={newCustomer}
            >
              Add Customer
            </button>
            <button
              className="mx-auto md:w-60 w-full h-12  mt-6
                         text-white border bg-[#008080] border-slate-200 
                         hover:bg-indigo-600/20
                         hover:text-indigo-600 rounded-md"
              type="submit"
            >
              Submit
            </button>  
          </div>
        </form>
      </div>
    </div>
    
  );
};

export default Form;
