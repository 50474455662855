import React, {useState} from "react";
import { Link, animateScroll as scroll} from 'react-scroll'
import { Menu } from '@styled-icons/entypo/Menu'
import { Cross } from "@styled-icons/entypo/Cross"
import { Link as ReactLink } from "react-router-dom";

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    const handleClose = () => setNav(!nav)

    return (
        <div className='w-full h-[80px] z-10 xsm:bg-indigo-600 bg-zinc-200 fixed drop-shadow-lg'>
                       <div className="px-2 flex justify-between items-center w-full h-full" >
                <div className="flex items-center">
                <Link to="title" smooth={true} duration={500}>  
                    <h1 className="text-3xl font-bold mr-4 sm:text-4xl">CareTeam Referrals</h1>
                </Link>
                    <ul className="hidden md:flex">
                    <li><Link className="cursor-pointer" to="referral" smooth={true} duration={500}>Referral</Link></li>
                    <li><Link className="cursor-pointer" to="benefits" smooth={true} offset={-200} duration={500}>Benefits</Link></li>
                    <li><Link className="cursor-pointer" to="contact" smooth={true} offset={-50} duration={500}>Contact</Link></li>
                    </ul>
                </div>
                <ReactLink to="/admin">
                    <p className="text-m hidden md:block font-bold">Admin</p>
                </ReactLink>
                <div className="md:hidden mr-4 cursor-pointer" onClick={handleClick}>
                    {!nav ? <Menu className="w-5" /> : <Cross className="w-5" />}
                </div>
                
            </div>

            <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
                <li className='border-b-2 border-zinc-300 w-full '><Link className='cursor-pointer'to="referral" smooth={true} duration={500} onClick={handleClose}>Referral</Link></li>
                <li className='border-b-2 border-zinc-300 w-full '><Link className="cursor-pointer" to="benefits" smooth={true} offset={-200} duration={500} onClick={handleClose}>Benefits</Link></li>
                <li className='mb-2 border-b-2 border-zinc-300 w-full '><Link className="cursor-pointer" to="contact" smooth={true} offset={-50} duration={500} onClick={handleClose}>Contact</Link></li>
                <li className='mb-2 border-b-2 border-zinc-300 w-full '><ReactLink className="cursor-pointer" to="/admin" onClick={handleClose}>Admin</ReactLink></li>
            </ul>
        </div>

)
}

export default Navbar