import Navbar from "./components/Navbar";
import Form from "./components/Form";
import Benefits from "./components/Benefits";
import Title from "./components/Title";
import Footer from "./components/Footer";
import { Switch, Route } from "react-router-dom";
import Admin from "./components/Admin";
import Success from "./components/Success";
import NavbarLogoOnly from "./components/NavbarLogoOnly";
import NotFound from "./components/NotFound";
import AdminSearch from "./components/AdminSearch";
import AdminNavbar from "./components/AdminNavbar";
import Auth0ProviderWithHistory from "./auth0Provider";

function App() {
  return (
    <div>
      <Auth0ProviderWithHistory>
        <Switch>
          <Route exact path="/">
            <Title />
            <Benefits />
            <Form />
            <Footer />
          </Route>

          <Route exact path="/success">
            <NavbarLogoOnly />
            <Success />
          </Route>

          <Route exact path="/admin">
            <AdminNavbar />
            <Admin />
          </Route>

          <Route exact path="/admin-search">
            <AdminNavbar />
            <AdminSearch />
          </Route>

          <Route>
            <NavbarLogoOnly />
            <NotFound />
          </Route>
        </Switch>
      </Auth0ProviderWithHistory>
    </div>
  );
}

export default App;
