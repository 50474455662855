import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Admin = () => {



const [referrals, setReferrals] = useState([

])



  async function getReferrals() {
    try {
      await axios.get(process.env.REACT_APP_DB_ALL)
      .then((data) => {setReferrals(data.data)})  
    } catch (error){
      console.log(error)
    }
    
  }

  useEffect(() => {
    getReferrals()
  }, []);

  const {loginWithRedirect,logout, user, isLoading} = useAuth0();

  return (
    <div>
    {!user ?
        <div className="w-screen h-screen grid place-items-center">
        <h1 className="font-bold text-3xl">Login For Admin Privileges!</h1>
    </div>

   :

    <div className="w-full h-screen grid place-items-center">
      <div className="mt-24 md:w-96 w-full mx-12">
        <h1 className="font-bold text-3xl text-center">Referrals</h1>
      {referrals.map((r) => (
        <div key={r.Referrer.phoneNumber} className="grid border-2 border-slate-400 rounded shadow-xl my-8 w-full text-xl">
            <p className="font-bold my-2">Referrer:</p>
            <p className="border-b-2 border-slate-400/50 w-full mt-2">{r.Referrer.firstName} {r.Referrer.lastName}: {r.Referrer.phoneNumber}</p>
            <p className="font-bold my-2 ">Customers:</p>
            {r.Customers.map((c) => (
              <div key={c.phoneNumber + c.firstName + c.lastName} className="flex">
                <p className="border-b-2 border-slate-400/50 w-full mt-2">{c.firstName} {c.lastName}: {c.phoneNumber}</p>
              </div>
            ))}
        </div>
      ))}
      </div>
    </div>
}
</div>
  )
}

export default Admin