import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link } from "react-router-dom";

const AdminNavbar = () => {
  const {loginWithRedirect,logout, user, isLoading} = useAuth0();
  return (
    <div className="w-screen h-[80px] z-10 xsm:bg-indigo-600 bg-zinc-200 fixed drop-shadow-lg">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <Link to="/">
            <h1 className="text-3xl font-bold mr-4 sm:text-4xl">
              CareTeam Referrals
            </h1>
          </Link>
          {window.location.pathname === "/admin" ? 
          <Link to="/admin-search" className="md:pt-4 pt-3 md:pl-4 mr-2 md:mr-0">Search</Link> 
          :
          <Link to="/admin" className="md:pt-4 pt-3 md:pl-4 mr-2 md:mr-0">Referrals</Link>} 
        </div>
        <div>
        {!isLoading && !user && (
            <button className="w-20 h-10 mr-2" onClick={() => loginWithRedirect()}>Log in</button>
          )}
          {!isLoading && user && (
            <button className="w-20 h-10 mr-2" onClick={() => logout()}>Log out</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
