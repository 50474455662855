import React from 'react'
import { useHistory } from "react-router-dom";

const NotFound = () => {

    let history = useHistory();


    function handleClick() {
        history.push("/");
      }


  return (
    <div name="title" className='bg-home bg-cover  h-screen'>
        <div> 
        </div>
        <div className='h-4/5 grid grid-cols-6 2xl:pt-96 md:pt-40 pt-20   mx-auto'>
            <div className='rounded-xl col-start-2 col-span-2'>
                <div className='h-full'>
                    <div className='grid'>
                    <p  className='font- text-3xl mt-12 italic'></p>
                    <p className='font- text-5xl mt-4'>Page Not Found</p>
                        <button className='md:mt-16 mt-8 h-10 w-3/6' onClick={handleClick}>Home</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default NotFound